<template>
  <div class="app-wrapper">
    <el-container  v-if="this.$store.getters.layoutType === 'layoutType3'">
      <div class="main">
          <div class="left-nav-class">
            <LeftMen />
          </div>
        <div class="layout-2-main">
          <el-header height="60px">
            <TopNav :layoutType="this.$store.getters.layoutType" :logo="true"></TopNav>
          </el-header>
          <div class="Type-router-class">
              <router-view v-if="isRouterAlive" />
          </div>
        </div>
      </div>
    </el-container>
    <el-container v-else>
      <div class="Type-main">
        <el-header height="45px">
          <TopNav :layoutType="this.$store.getters.layoutType" :logo="true"></TopNav>
        </el-header>
        <div class="Type-layout-main">
          <LeftNav :logo="false" v-if="$store.getters.layoutType == 'layoutType1'"></LeftNav>
          <LeftMenu :logo="false" v-else />
          <div class="Type-router-class">
            <router-view v-if="isRouterAlive" />
          </div>
        </div>
      </div>
    </el-container>

  </div>
</template>

<script>
import { getToken } from '@/utils/auth';
import { _getSessionStore } from '@/utils/storage';
import { LeftNav, TopNav } from './components';
import LeftMenu from './components/LeftMenu';
import LeftMen from './components/LeftMen';

export default {
  name: 'Layout',
  components: {
    LeftNav,
    TopNav,
    LeftMen,
    LeftMenu
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      layoutType: 'layoutType',
      asideWidth: '160px',
      isRouterAlive: true,
      obj: ''
    };
  },
  created() {
    this.obj = this.watermark({ text: '唐承数科' });
    this.$store.dispatch('config/setLayoutType', _getSessionStore('layoutType'));
    if (!getToken()) {
      this.$router.push('/login');
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
        
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-header,
.el-aside {
  padding: 0;
}
.footer {
  background-color: #0ff;
}
.el-main {
  padding: 15px 15px 0;
  // padding: 0;
}
// TYPE 1
.main {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
  .left-nav-class{
    height: 100vh;
    overflow-x: hidden;
    transition: width .3s cubic-bezier(.2,0,0,1) 0s,left .3s cubic-bezier(.2,0,0,1) 0s;
    // width: 220px;
    // background-color: #00f;
  }
.layout-main {
  width: calc(100% - 80px);
  min-width: 750px;
  min-height: 500px;
  background-color: #f0f2f5;
  overflow: auto;
}
.layout-2-main{
  width: calc(100% - 80px);
  min-width: 750px;
  min-height: 500px;
  background-color: #f0f2f5;
  overflow: auto;
}
.router-class {
  width: 100%;
  max-height: calc(100vh - 45px);
  padding: 15px;
  // background-color: #0f0;
  overflow: auto;
}
// TYPE 2
.Type-main {
  // display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.Type-layout-main {
  display: flex;
  width: calc(100% );
  min-width: 750px;
  min-height: 500px;
  background-color: #f0f2f5;
  overflow: auto;
}
.Type-router-class {
  width: 100%;
  max-height: calc(100vh - 45px);
  padding: 15px;
  // background-color: #0f0;
  overflow: auto;
}
/*内容区*/
.app-main {
  padding: 59px 15px 0px 65px;
  height: 100%;
  width: 100%;
  background-color: #f0f2f5;
  box-sizing: border-box;
}
</style>

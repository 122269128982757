<template>
  <el-aside width="60px">
    <div class="left-nav">
      <!-- <div class="left-nav__item" v-xzwaves="'aa'" disabled>
        <i :class="`iconfont icon-wentijieda`" />
        <span class="left-nav__item--name">{测试</span>
      </div> -->
      <div
        class="left-nav__item"
        :class="{ 'v-active': currentPath === item.path }"
        :key="key"
        v-for="(item, key) in navList"
        v-waves
        @click="jump(item)"
      >
        <i :class="`iconfont ${item.icon}`" />
        <span class="left-nav__item--name">{{ item.name }}</span>
      </div>
    </div>
  </el-aside>

</template>

<script>
import './index.scss';
import router from '@/router';

export default {
  name: 'index',
  data() {
    return {
      navList: [
        { name: '首页', path: '/', icon: 'icon-gongyezujian-yibiaopan' },
        {
          path: '/project',
          component: 'Layout',
          redirect: '/project/list',
          name: '项目',
          icon: 'icon-yingyongguanli',
          meta: {
            title: '项目',
            icon: 'example'
          },
          children: [
            { // 微页面列表
              path: 'project-list',
              component: 'project/list/index',
              name: 'featureList',
              meta: { title: '微页面' }
            }
          ]
        },
        { name: '组件', path: '/a-render', icon: 'icon-gongnengdingyi1' },
        { name: '资源', path: '/resources', icon: 'icon-gongnengdingyi1' },
        { name: '知识', path: '/knowledge', icon: 'icon-wentijieda' },
        { name: '字典', path: '/dictionary', icon: 'icon-peiwangyindao' },
        { name: '代码', path: '/a-render', icon: 'icon-daima' },
        { name: '图表', path: '/t4', icon: 'icon-shujukanban' },
        { name: '流程', path: '/t6', icon: 'icon-lianjieliu' },
        { name: '设置', path: '/sys/menu', icon: 'icon-tiaoshi' },
        // { name: '设置', path: '/sys/menu', icon: 'icon-tiaoshi' },
        // { name: '设置', path: '/sys/menu', icon: 'icon-tiaoshi' },
        // { name: '设置', path: '/sys/menu', icon: 'icon-tiaoshi' },
        // { name: '设置', path: '/sys/menu', icon: 'icon-tiaoshi' },
        // { name: '设置', path: '/sys/menu', icon: 'icon-tiaoshi' },
        // { name: '设置', path: '/sys/menu', icon: 'icon-tiaoshi' },
        // { name: '设置', path: '/sys/menu', icon: 'icon-tiaoshi' },
        // { name: '设置', path: '/sys/menu', icon: 'icon-tiaoshi' },
        // { name: '设置', path: '/sys/menu', icon: 'icon-tiaoshi' },
        // { name: '设置', path: '/sys/menu', icon: 'icon-tiaoshi' },
        { name: '关于', path: '/about', icon: 'icon-gaojingkongxin' }
      ],
      currentPath: '/'
    };
  },
  created() {
    console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
    console.log(this.$route.path);
    console.log('@@@@@!!!@@@@', this.$route.fullPath);
    console.log('@@@@@!!!@@@@', this.$route);
    console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
    this.currentPath = this.$route.path === '/dashboard' ? '/' : this.$route.path;
    // this.$route.path
  },
  methods: {
    jump(item) {
      if (this.currentPath === item.path) {
        return true;
      }
      this.currentPath = item.path;
      router.push(item.path);
    }
  }
};
</script>

<style scoped>

</style>

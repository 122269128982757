<template>
  <div class="top-nav">
    <div class="top-nav__logo" v-if="logo" @click="test">
      <div class="top-nav__content">
        <!-- <span class="top-nav__content--title"> <i class="iconfont icon-liujisuan" />天枢 </span>
        <br />
        <span class="top-nav__content--desc">TIANSHU</span> -->
      </div>
    </div>

    <div class="top-nav__left">
      <TopMenu v-if="$store.getters.layoutType === 'layoutType1'" />
      <BreadCrumbs v-else />
    </div>
    <div class="top-nav__right">
         <el-avatar v-if="$store.getters.userInfo.avatar && $store.getters.userInfo.avatar != ''"
          :size="32"
          :src="$store.getters.userInfo.avatar"></el-avatar>
          <el-avatar v-else :size="32" > {{$store.getters.userInfo.nick_name}} </el-avatar>
      <div style="margin-right: 10px;">
      </div>
      <el-dropdown trigger="click"  @command="handleCommand">
        <span class="el-dropdown-link">
          {{$store.getters.userInfo.nick_name}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" >
          <el-dropdown-item icon="el-icon-edit-outline" command="1">修改密码</el-dropdown-item>
          <el-dropdown-item icon="el-icon-switch-button" command="2" @click="logout">退出登陆</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="top-nav__item">
          <!-- <i class="el-icon-setting" @click="LayoutSettings" style="font-size:20px;" /> -->
      </div>
    </div>
    <el-drawer
      :title="drawerTitle"
      :visible.sync="drawer"
      direction="rtl">
      <div class="drawer-content">
        <div>
          <SingleChoice v-show="true" @chenge="chenge" :data="$store.getters.layoutType" :urlList="urlList" :icon="'el-icon-success'" />
          <TableConfig />
        </div>
      </div>
    </el-drawer>
  </div>
</template>     

<script>
import './index.scss';
import * as user from '@/api/user.js';
import { removeToken, getToken, removeUserInfo } from '@/utils/auth';
import router from '@/router';
import TopMenu from './components/TopMenu';
import BreadCrumbs from './components/BreadCrumbs';
import SingleChoice from './components/SingleChoice';
import TableConfig from './components/TableConfig';

export default {
  name: 'index',
  components: {
    TopMenu,
    BreadCrumbs,
    SingleChoice,
    TableConfig
  },
  props: {
    Type: {
      type: String,
      required: ''
    },
    logo: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {

      form: {
        card: false
      },
      urlList: [
        {
          id: 'layoutType1',
          url: '@/assets/img/layout/1.png'
        },
        {
          id: 'layoutType2',
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        },
        {
          id: 'layoutType3',
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        }
      ],
      // url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      drawer: false,
      layoutType: '',
      drawerTitle: ''

    };
  },
  created() {
    this.layoutType = this.$store.getters.layoutType;
  },
  watch: {
    layoutType(newV) {
      console.log(newV, this.layoutType);
      this.$store.dispatch('config/setLayoutType', newV);
    }
  },
  methods: {
    handleCommand(e) {
      console.log('------', e);
      switch (e) {
        case '1':
          break;
        case '2':
          this.logout();
          break;

        default:
          break;
      }
    },
    test() {
      this.$router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.fullPath
        }
      });
    },
    chenge(e) {
      this.$store.dispatch('config/setLayoutType', e.id);
      console.log(e.id, '这是ID');
      console.log('5555');
    },
    LayoutSettings() { 
      this.drawerTitle = '布局设置';
      this.drawer = true;
    },
    logout() {
      // logout
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(user);
        // console.log('@@@@@!!!@@@@', this.$route.fullPath);
        user.logout().then(res => {
          console.log(res);
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
          removeToken();
          removeUserInfo();
          console.log(getToken());
          // sessionStorage.clear();

          this.$router.push('/login');
        });
      });
      // await this.$store.dispatch('user/logout')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
};
</script>

<style lang="scss">
.el-popover{
  padding: 0;
}
.popover-content{
    width: 100%;
    .content-item{
      &:hover{
        color: royalblue;
        background-color: #d4e9ff;
      }
      &.logout{
        border-top: 1px solid #dddd;
      }
      height: 30px;
      width: 100%;
      // border-bottom: 1px solid #000;
      // margin-bottom: 10px;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .drawer-content{
    width: 100%;
    padding: 10px;
    // background-color: #0ff;
  }
</style>

<template>
  <div class="top-nav">
      <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item
            v-for="(item, index) in list"
            :key="index"
            class="el-menu-item" :index="index">{{item.title}}</el-menu-item>
        </el-menu>
  </div>
</template>

<script>
import './index.scss';

export default {
  name: 'index',
  data() {
    return {
      activeIndex: '',
      list: [
        //     <router-link to="/atest" class="top-nav__item">测试</router-link> |
        {
          url: '/atest',
          title: '测试'
        },
        // <router-link to="/component" class="top-nav__item">组件</router-link> |
        {
          url: '/component',
          title: '组件'
        },
        // <router-link to="/code" class="top-nav__item">代码编辑</router-link> |
        {
          url: '/code',
          title: '代码编辑'
        },
        {
          url: '/sys/role',
          title: '角色页面'
        },
        // <router-link to="/sys/log/logininfor" class="top-nav__item">登陆日志</router-link> |
        {
          url: '/sys/log/logininfor',
          title: '登陆日志'
        },
        // <router-link to="/sys/log/operlog" class="top-nav__item">操作日志</router-link>
        {
          url: '/sys/log/operlog',
          title: '操作日志'
        }
      ]
    };
  },
  methods: {
    logout() {
      // logout
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // removeToken();
        console.log(user);
        // console.log('@@@@@!!!@@@@', this.$route.fullPath);
        user.logout().then(res => {
          console.log(res);
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
          removeToken();
          sessionStorage.clear();
          this.$router.push('/login');
        });
      });
      // await this.$store.dispatch('user/logout')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    handleSelect(e) {
      console.log('点击菜单', e);
    }
  }
};
</script>

<style lang="scss" scoped></style>

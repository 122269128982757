<template>
  <div class="cm-loading-mini">
    <div class="cm-loading__icon cm-loading__icon__z-block">
      <div class="cm-loading__split"></div>
      <div class="cm-loading__split"></div>
      <div class="cm-loading__split"></div>
      <div class="cm-loading__split"></div>
    </div>
    <span class="cm-loading__mini-text">加载中</span>
  </div>
</template>

<script>
import index from './index';

export default index;
</script>

/**
 * 全局mixins
 *  tags的一些方法
 *  menuTag  关闭某个tab窗口
 *  this.menuTag(this.$route.pathFull, 'remove', "/shop/micro");
 * */
export default {
  name: 'commonMixins',
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    myTagsRouter() { // 获取当前的标签列表
      return this.$store.state.tagsView;
    }
  },
  mounted() {
  },
  methods: {}
};

<template>
 <div class="sub-menu__list">
   <div class="sub-menu__list-tools" v-if="create">
     <Button icon="icon-cc-plus-square">{{ listCreateButtonName }}</Button>
   </div>
   <div
     class="sub-menu__list-item"

     :class="{ 'z-active': listIndex === index }"
     v-waves
     @click="onClick(index)"
     @contextmenu.prevent="onClick(index)"
     :key="index"
     v-for="(item, index) in list"
   >
     <div class="sub-menu__item-left">
       <i class="iconfont icon-biaoge" />
     </div>
     <div class="sub-menu__item-right">
       <span class="sub-menu__right-title f-ellipsis">{{ item.name }}</span>
       <span v-if="item.desc" class="sub-menu__right-desc f-ellipsis">{{ item.desc }}</span>
     </div>
   </div>
 </div>
</template>

<script>
import './style/list.scss';
import props from './props';

export default {
  name: 'list',
  props,
  data() {
    return {
      visible: true,
      typeName: '',
      typeEditName: ''
    };
  },
  methods: {
    onChange() {}
  }
};
</script>

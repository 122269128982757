<template>
  <div class="sub-menu__type">
    <div
      class="sub-menu__type-item"
      :class="{'z-active': typeIndex === index}"
      v-waves
      @contextmenu.prevent="onClick(index, item)"
      @click="onClick(index, item)"
      :key="index"
      v-for="(item, index) in typeList"
    >
      <el-popover v-model="item.visibleEdit" v-if="typeIndex === index && typeEdit === true" placement="rightTop" trigger="none">
        <template #content>
          <div class="sub-menu__add-type">
            <h2>编辑分类</h2>
            <a-input v-model="typeEditName" @keydown.enter.stop="editType" placeholder="请输入分类名称" />
            <div class="sub-menu__tools">
              <Button class="button--primary button--small" @click="editType">编辑分类</Button>
              <Button class="cancel button--small" @click="item.visibleEdit = false">取消</Button>
            </div>
          </div>
        </template>
        {{ item.name }}
      </el-popover>
      <span v-else>{{ item.name }}</span>
    </div>

    <el-popover v-model="visible" :destroyTooltipOnHide="true" v-if="typeCreate === true" placement="rightTop" trigger="click">
      <template #content>
        <div class="sub-menu__add-type">
          <h2>添加分类</h2>
          <a-input v-model="typeName" @keydown.enter.stop="addType" placeholder="请输入分类名称" />
          <div class="sub-menu__tools">
            <Button class="button--primary button--small" @click="addType">添加分类</Button>
            <Button class="cancel button--small" @click="visible = false">取消</Button>
          </div>
        </div>
      </template>
      <div v-waves class="sub-menu__type-item z-plus" :class="{ 'z-active': visible }" @click="visible = true">
        <i class="iconfont icon-plus" />
      </div>
    </el-popover>
  </div>
</template>

<script>
import './style/type.scss';
import props from './props';

export default {
  name: 'type',
  props,
  data() {
    return {
      visible: true,
      typeName: '',
      typeEditName: ''
    };
  },
  methods: {
    onChange() {}
  }
};
</script>

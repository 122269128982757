import { login, logout, getInfo } from '@/api/user';
import {
  getToken,
  setToken,
  removeToken,
  removeUserInfo,
  setUserInfo
} from '@/utils/auth';
// import router, { resetRouter } from '@/router';

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  userInfo: {}
};

const mutations = {
  SET_USER_INFO: (state, data) => {
    state.userInfo = data;
    console.log(state.userInfo, data, 'state.userInfo');
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  }
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(response => {
        const { token } = response.data;
        console.log(token);
        commit('SET_TOKEN', token);
        setToken(token);
        if (userInfo.autologin) {
          setUserInfo(userInfo);
        } else {
          removeUserInfo();
        }
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response.data;

        if (!data) {
          reject('Verification failed, please Login again.');
        }

        const { roles, name, avatar, introduction } = data;

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!');
        }

        commit('SET_ROLES', roles);
        commit('SET_NAME', name);
        commit('SET_AVATAR', avatar);
        commit('SET_INTRODUCTION', introduction);
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  setUserInfo({ commit }, data) {
    commit('SET_USER_INFO', data);
  },
  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        removeToken();
        resetRouter();
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

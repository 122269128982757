import {
  _setSessionStore
} from '@/utils/storage';

const state = {
  layoutType: 'layoutType3',
  menuList: [],
  MenuType: {
    asideWidth: '50px',
    rightMenuType: false,
    ParentMenuUrl: 2019, // 一级菜单目录
    SubmenuUrl: '/', // 子菜单目录
    SubmenuTitle: '' // 子菜单title
  },
  SubmenuList: [],
  tableConfig: {
    size: 'small', // medium / small / mini
    searchMenuSpan: 4,
    card: true, // 卡片
    border: true, // 边框
    filterBtn: true, // 筛选
    tip: false, // 多选表格上方显示的tag
    labelWidth: 120,
    cancelBtn: true,
    selection: true,
    index: true,
    searchSize: 'small'
  }
};

const mutations = {
  SET_ERROR_LAYOUTYPE: (state, layoutType) => {
    state.layoutType = layoutType;
  },
  SET_MENU_LIST: (state, menuList) => {
    const filterMenuList = (list) => {
      // debugger
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item.menu_type === 'F' || (item.visible === 1 && item.menu_type === 'C')) {
          list.splice(i, 1);
          i--;
        } else if (item.menu_type === 'C') {
          // delete list[i].children
          list[i].children = null;
        } else if (item.menu_type === 'M' && item.children && item.children.length) {
          filterMenuList(item.children);
        }
      }
      return list;
    };
    filterMenuList(menuList);
    state.menuList = menuList;
  },

  SET_NENU_TYPE: (state, data) => {
    state.MenuType = data;
    _setSessionStore(
      'navSelf',
      state.MenuType,
      'JSONStr'
    );
  },
  SET_SUBNENU_LIST: (state, data) => {
    state.SubmenuList = data;
    _setSessionStore(
      'SubmenuList',
      state.SubmenuList,
      'JSONStr'
    );
  },
  SET_TABLE_CONFIG: (state, data) => {
    state.tableConfig = {};
    state.tableConfig = {
      ...data,
      ...state.tableConfig
    };
    _setSessionStore(
      'TableConfig',
      data,
      'JSONStr'
    );
  }
};

const actions = {
  setLayoutType({ commit }, data) {
    commit('SET_ERROR_LAYOUTYPE', data);
    _setSessionStore(
      'layoutType',
      data
    );
  },
  setmenuList({ commit }, data) {
    commit('SET_MENU_LIST', data);
  },

  setSubmenuList({ commit }, data) {
    commit('SET_SUBNENU_LIST', data);
  },
  setMenuType({ commit }, data) {
    commit('SET_NENU_TYPE', data);
  },

  setUserInfo({ commit }, data) {
    commit('SET_USER_INFO', data);
  },
  setTableConfig({ commit }, data) {
    commit('SET_TABLE_CONFIG', data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

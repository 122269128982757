import Vue from 'vue';
import { Message } from 'element-ui';

Vue.component(Message.name, Message);

/* eslint-disable no-eval */
/* eslint-disable no-useless-escape */
/* eslint-disable import/order */
/* eslint-disable import/order */
/* eslint-disable array-callback-return */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable no-void */
/* eslint-disable prefer-template */
/* eslint-disable vars-on-top */
/* eslint-disable spaced-comment */
/* eslint-disable no-else-return */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-multiple-empty-lines */

// no-tabs
// sessionStorage
export const session = function(key, value) {
  // key 为 'token' 查找当前用户的token
  if (value === void 0) {
    const lsVal = sessionStorage.getItem(key);
    if (lsVal && lsVal.indexOf('autostringify-') === 0) {
      return JSON.parse(lsVal.split('autostringify-')[1]);
    } else {
      return lsVal;
    }
  } else {
    if (typeof value === 'object' || Array.isArray(value)) {
      value = 'autostringify-' + JSON.stringify(value);
    }
    return sessionStorage.setItem(key, value);
  }
};

//生成随机数
export const getUUID = function(len) {
  len = len || 6;
  len = parseInt(len, 10);
  len = Number.isNaN(len) ? 6 : len;
  const seed = '0123456789abcdefghijklmnopqrstubwxyzABCEDFGHIJKLMNOPQRSTUVWXYZ';
  const seedLen = seed.length - 1;
  let uuid = '';
  while (len--) {
    uuid += seed[Math.round(Math.random() * seedLen)];
  }
  return uuid;
};
//深拷贝
export const deepcopy = function(source) {
  if (!source) {
    return source;
  }
  const sourceCopy = source instanceof Array ? [] : {};

  for (const item in source) {
    sourceCopy[item] =
      typeof source[item] === 'object' ? deepcopy(source[item]) : source[item];
  }
  return sourceCopy;
};
//菜单数据组织
export const buildMenu = function(array, ckey) {
  const menuData = [];
  console.log('menuData----*--------------', menuData, array);
  const indexKeys = Array.isArray(array)
    ? array.map((e) => {
      return e.id;
    })
    : [];
  ckey = ckey || 'parent_id';
  array.forEach(function(e) {
    //一级菜单
    if (!e[ckey] || e[ckey] === e.id) {
      delete e[ckey];
      menuData.push(deepcopy(e)); //深拷贝
    } else if (Array.isArray(indexKeys)) {
      //检测ckey有效性
      const parentIndex = indexKeys.findIndex(function(id) {
        return id === e[ckey];
      });
      if (parentIndex === -1) {
        menuData.push(e);
      }
    }
  });
  const findChildren = function(parentArr) {
    if (Array.isArray(parentArr) && parentArr.length) {
      parentArr.forEach(function(parentNode) {
        array.forEach(function(node) {
          if (parentNode.id === node[ckey]) {
            if (parentNode.children) {
              parentNode.children.push(node);
            } else {
              parentNode.children = [node];
            }
          }
        });
        if (parentNode.children) {
          findChildren(parentNode.children);
        }
      });
    }
  };
  console.log('menuData------------', menuData, array);
  findChildren(menuData);
  return menuData;
};


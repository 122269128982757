import {
  _getSessionStore
} from '@/utils/storage';
// const tableDate = {
//   size: 'small', // medium / small / mini
//   searchMenuSpan: 4,
//   card: true, // 卡片
//   border: true, // 边框
//   filterBtn: true, // 筛选
//   tip: false, // 多选表格上方显示的tag
//   labelWidth: 120,
//   cancelBtn: true,
//   selection: true,
//   index: true,
//   searchSize: 'small'
// };

export default {
  BtnSize: 'mini', // 按钮大小
  menuType: {
    leftBGC: '#fff',
    backgroundColor: '#545c64',
    textColor: '#fff',
    activeCextColor: '#ffd04b'
  },
  table: { // 表格样式
    ..._getSessionStore('TableConfig', 'JSONStr'),
    // ...tableDate
    searchMenuSpan: 4,

    labelWidth: 120,
    cancelBtn: true,
    selection: true
  },
  addBtnColor: '', // 添加按钮样式
  alioss: {
    region: 'cn-zhangjiakou',
    endpoint: 'i.tangchengshuke.com', // 'oss-accelerate.aliyuncs.com',
    accessKeyId: 'LTAI5tECP64MemjHdubAiVCE',
    accessKeySecret: '58kDJz9QJcD9swYV6USD1Gz1MsfeKy',
    bucket: 'tcsk001',
    cname: true
  }
};
